import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withComponentInputBinding, withPreloading } from '@angular/router';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { APP_REDUCERS } from '@store/app.state';

import { provideFeatureFlags } from '@core/data-access/feature-flags-app-initializer';
import { InterceptorsModule } from '@core/interceptors/interceptors.module';
import { TranslationsModule } from '@core/translations.module';

import { appRoutes } from './app-routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes, withPreloading(PreloadAllModules), withComponentInputBinding()),
        provideHttpClient(withFetch()),
        importProvidersFrom(BrowserAnimationsModule),
        importProvidersFrom(InterceptorsModule),
        importProvidersFrom(TranslationsModule),
        provideStore(APP_REDUCERS, {
            runtimeChecks: {
                // strictStateImmutability and strictActionImmutability are enabled by default
                strictStateSerializability: false,
                strictActionSerializability: false,
                strictActionWithinNgZone: true,
                strictActionTypeUniqueness: true,
                // if you want to change complexe objects and that we have. We need to disable these settings
                // change strictStateImmutability, strictActionImmutability
                strictStateImmutability: false, // set this to false
                strictActionImmutability: false,
            },
        }),
        provideStoreDevtools({ maxAge: 25 }),
        provideFeatureFlags(),
    ],
};
