import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { AccountsService } from '@api/accounts/accounts.service';
import { Store } from '@ngrx/store';
import { User } from '@shared/models/interfaces/user.interface';
import { loadUser } from '@store/actions/user.actions';
import { IAppState } from '@store/app.state';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

export const userResolver: ResolveFn<User> | any = async (route: ActivatedRouteSnapshot) => {
    const accountService = inject(AccountsService);
    const store = inject(Store<IAppState>);

    if ('setup_intent' in route.queryParams && route.routeConfig?.path?.includes('dashboard')) {
        await new Promise((resolve) => setTimeout(resolve, 2000)); //Wait for stripe hook to our api
    }

    const user = await firstValueFrom(accountService.getUser());
    store.dispatch(loadUser({ user }));
    return user;
};
